<script>
import { PageTabs, PageView } from '@/components';
import FleetRouterMap from '../router/FleetRouterMap';

export default {
  name: 'HardwareView',
  components: {
    PageTabs,
    PageView,
  },
  created() {
    this.tabs = [
      {
        label: {
          title: 'Device providers',
        },
        url: FleetRouterMap.hardware.deviceProviders,
      },
      {
        label: {
          title: 'Devices',
        },
        url: FleetRouterMap.hardware.devices,
      },
      {
        label: {
          title: 'Sims',
        },
        url: FleetRouterMap.hardware.sims,
      },
      {
        label: {
          title: 'Fuel cards',
        },
        url: FleetRouterMap.hardware.fuelCards,
      },
    ];
  },
};
</script>

<template>
  <div class="Hardware d-flex flex-column flex-fill">
    <PageView class="emobg-background-color-white py-4">
      <h2>
        Hardware
      </h2>
    </PageView>
    <PageTabs
      :tabs="tabs"
      has-overview
    />
    <PageView is-tab-content>
      <Transition name="page">
        <RouterView />
      </Transition>
    </PageView>
  </div>
</template>
