var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "DeviceProvidersListView",
      attrs: { "data-test-id": "devices_list-view" },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center justify-content-between mb-2",
        },
        [
          _c("h1", [_vm._v(" Devices ")]),
          _c(
            "ui-button",
            {
              attrs: { "data-test-id": "open_form-button" },
              on: {
                clickbutton: function ($event) {
                  _vm.isFormOpened = true
                },
              },
            },
            [_vm._v(" Create device ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: {
                  label: "Loading devices list...",
                  absolute: "",
                  "data-test-id": "loader",
                },
              })
            : _vm._e(),
          _c("MuiAlgoliaList", {
            ref: "devicesList",
            attrs: {
              "item-actions": _vm.itemsActions,
              "export-columns": _vm.contentCells,
              facets: _vm.facets,
              filters: _vm.getOperatorFilter(),
              index: _vm.ALGOLIA_INDEXES.devices,
              "table-config": _vm.contentCells,
              "data-test-id": "list",
              "is-export-enabled": "",
            },
          }),
        ],
        1
      ),
      _vm.isFormOpened
        ? _c("DeviceFormComponent", {
            attrs: {
              "device-uuid": _vm.uuidToEdit,
              "on-success": _vm.refreshList,
              "data-test-id": "edit-form",
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isDeleteFormOpened
        ? _c("DeleteDeviceComponent", {
            attrs: {
              device: _vm.device,
              "on-success": _vm.refreshList,
              "data-test-id": "delete_device",
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }