var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "DeviceFormComponent",
        attrs: {
          header: null,
          buttons: _vm.modalButtons,
          size: _vm.SIZES.small,
          "data-test-id": "device-form",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c("DeleteModalBodyComponent", {
            attrs: {
              text: `${_vm.device.device} - ${_vm.device.device_number} - ${_vm.device.label_number}`,
              title: "Are you sure you want to remove this device?",
              "data-test-id": "delete_modal_body",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }