var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "SimForm",
        attrs: { title: _vm.modalTitle, header: { isClosable: true } },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "alerts" },
        [
          _c("StoreNotificationComponent", {
            attrs: {
              "store-domain": _vm.fleet.sims,
              scope: _vm.SIM_SCOPES.newSim,
              "is-editing": !!_vm.simUuid,
              element: "sim",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          !_vm.isInitialized
            ? _c("ui-loader", { attrs: { label: "Loading sim..." } })
            : _c(
                "MuiValidationWrapper",
                { on: { areAllValid: (valid) => (_vm.isFormValid = valid) } },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-6 emobg-border-right-1 emobg-border-color-ink-lighter emobg-border-radius-none",
                      },
                      [
                        _c("div", { staticClass: "pr-2" }, [
                          _c(
                            "div",
                            { staticClass: "mb-4" },
                            [
                              _c("MuiInputText", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: { isRequired: true },
                                    expression: "{ isRequired: true }",
                                  },
                                ],
                                staticClass: "w-100",
                                attrs: {
                                  label: "Data number*",
                                  placeholder: "Enter the sim data number",
                                  name: "dataNumber",
                                },
                                model: {
                                  value: _vm.inputs.dataNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.inputs, "dataNumber", $$v)
                                  },
                                  expression: "inputs.dataNumber",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mb-4" },
                            [
                              _c("MuiInputText", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: { isRequired: true },
                                    expression: "{ isRequired: true }",
                                  },
                                ],
                                staticClass: "w-100",
                                attrs: {
                                  label: "PIN*",
                                  placeholder: "Enter the PIN",
                                  name: "pin",
                                },
                                model: {
                                  value: _vm.inputs.pin,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.inputs, "pin", $$v)
                                  },
                                  expression: "inputs.pin",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mb-4" },
                            [
                              _c("MuiInputText", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: { isRequired: true },
                                    expression: "{ isRequired: true }",
                                  },
                                ],
                                staticClass: "w-100",
                                attrs: {
                                  label: "GPRS IP*",
                                  placeholder: "Enter the GPRS IP",
                                  name: "gprsIp",
                                },
                                model: {
                                  value: _vm.inputs.gprsIp,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.inputs, "gprsIp", $$v)
                                  },
                                  expression: "inputs.gprsIp",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mb-4" },
                            [
                              _c("MuiInputText", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: { isRequired: true },
                                    expression: "{ isRequired: true }",
                                  },
                                ],
                                staticClass: "w-100",
                                attrs: {
                                  label: "Sim number*",
                                  placeholder: "Enter the Sim number",
                                  name: "simId",
                                },
                                model: {
                                  value: _vm.inputs.simId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.inputs, "simId", $$v)
                                  },
                                  expression: "inputs.simId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "pl-2" }, [
                        _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "d-block emobg-font-weight-semibold mb-1",
                              },
                              [_vm._v(" Active ")]
                            ),
                            _c("ui-toggle", {
                              staticClass: "py-1",
                              attrs: {
                                value: _vm.inputs.active,
                                text: _vm.inputs.active ? "Active" : "Inactive",
                                name: "sim",
                                "data-test-id": "active-toggle",
                              },
                              on: {
                                changevalue: ({ detail }) =>
                                  (_vm.inputs.active = detail),
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _c("MuiInputText", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: { isRequired: true },
                                  expression: "{ isRequired: true }",
                                },
                              ],
                              staticClass: "w-100",
                              attrs: {
                                label: "Voice number*",
                                placeholder: "Enter the sim voice number",
                                name: "voiceNumber",
                              },
                              model: {
                                value: _vm.inputs.voiceNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, "voiceNumber", $$v)
                                },
                                expression: "inputs.voiceNumber",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _c("MuiAlgoliaSelect", {
                              staticClass: "w-100",
                              attrs: {
                                index: _vm.ALGOLIA_INDEXES.deviceProviders,
                                title: (provider) => provider.name,
                                label: "Device provider",
                                name: "provider",
                                "no-cache": "",
                                "show-clear": "",
                                "path-value": "name",
                                placeholder: "Select a provider",
                              },
                              model: {
                                value: _vm.inputs.provider,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, "provider", $$v)
                                },
                                expression: "inputs.provider",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _c("MuiInputText", {
                              staticClass: "w-100",
                              attrs: {
                                label: "Puk",
                                placeholder: "Enter the sim puk",
                                name: "puk",
                              },
                              model: {
                                value: _vm.inputs.puk,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, "puk", $$v)
                                },
                                expression: "inputs.puk",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center justify-content-sm-end align-items-center",
          },
          [
            _c("CancelButton", {
              on: { click: () => _vm.$emit("closeModal") },
            }),
            _c(
              "ui-button",
              {
                staticClass: "wmin-initial",
                attrs: {
                  disabled: !_vm.areRequiredFieldsFilled || _vm.hasSameValues,
                  loading: _vm.newSimStatus.LOADING,
                  "data-test-id": "sim-form-action-button",
                },
                on: { clickbutton: _vm.request },
              },
              [_vm._v(" " + _vm._s(_vm.simUuid ? "Save" : "Create") + " ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }