var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SimsListView", attrs: { "data-test-id": "sims-list" } },
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-center mb-2" },
        [
          _c("h1", { staticClass: "flex-grow-1" }, [_vm._v(" Sims ")]),
          _c(
            "ui-button",
            {
              attrs: { "data-test-id": "create-button" },
              on: {
                clickbutton: function ($event) {
                  _vm.isModalVisible = true
                },
              },
            },
            [_vm._v(" Add sim ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.isLoading
            ? _c("ui-loader", { attrs: { absolute: "" } })
            : _vm._e(),
          _c("MuiAlgoliaList", {
            ref: "sims",
            attrs: {
              facets: _vm.facets,
              "item-actions": _vm.itemsActions,
              index: _vm.ALGOLIA_INDEXES.sims,
              "table-config": _vm.mainConfig,
              "data-test-id": "list",
            },
          }),
          _vm.isModalVisible
            ? _c("SimForm", {
                attrs: { "sim-uuid": _vm.simUuid, callback: _vm.onFormSuccess },
                on: { closeModal: _vm.closeModal },
              })
            : _vm._e(),
          _vm.isDeleteModalVisible
            ? _c("DeleteSimComponent", {
                attrs: { "sim-uuid": _vm.simUuid, callback: _vm.onFormSuccess },
                on: { closeModal: _vm.closeModal },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }