import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import { FALLBACK_MESSAGE, isFalsyString, sentenceCase } from '@emobg/web-utils';

export const contentCells = [
  {
    attributeName: 'uuid',
    title: 'UUID',
    displayPriority: 1,
    minWidth: 100,
  },
  {
    attributeName: 'device',
    title: 'Device',
    displayPriority: 1,
    minWidth: 150,
  },
  {
    attributeName: 'device_number',
    title: 'Device number',
    displayPriority: 1,
    minWidth: 150,
  },
  {
    attributeName: 'type',
    title: 'Type',
    displayPriority: 1,
    minWidth: 150,
    transformValue: sentenceCase,
  },
  {
    attributeName: 'active',
    title: 'Status',
    displayPriority: 1,
    minWidth: 150,
    type: RECORD_TYPES.component,
    component: BadgeComponent,
    props: result => ({
      text: result.active ? 'Active' : 'Inactive',
      color: result.active ? COLORS.success : GRAYSCALE.ground,
    }),
  },
  {
    title: 'Vehicle',
    displayPriority: 1,
    minWidth: 150,
    transformResult: result => (isFalsyString(result.vehicle_id) ? FALLBACK_MESSAGE.dash : `${result.vehicle_license_plate} ${result.vehicle_model}`),
  },
  {
    attributeName: 'label_number',
    title: 'Label number',
    displayPriority: 2,
    minWidth: 150,
  },
  {
    attributeName: 'firmware_version',
    title: 'Firmware version',
    displayPriority: 2,
    minWidth: 150,
  },
  {
    attributeName: 'orga_ref',
    title: 'Orga ref',
    displayPriority: 2,
    minWidth: 150,
  },
  {
    attributeName: 'qnr',
    title: 'Qnr',
    displayPriority: 2,
    minWidth: 150,
  },
  {
    attributeName: 'communication_system',
    title: 'Communication system',
    displayPriority: 2,
    minWidth: 150,
  },
  {
    attributeName: 'sim_id',
    title: 'Sim ID',
    displayPriority: 2,
    minWidth: 150,
  },
];
