var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "FuelCardsListView",
      attrs: { "data-test-id": "fuel_cards_list-view" },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-center mb-2" },
        [
          _c("h1", { staticClass: "flex-grow-1" }, [_vm._v(" Fuel cards ")]),
          _c(
            "ui-button",
            {
              attrs: { "data-test-id": "create-button" },
              on: {
                clickbutton: function ($event) {
                  _vm.isModalVisible = true
                },
              },
            },
            [_vm._v(" Add fuel card ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _vm.isUserLoaded
            ? _c("MuiAlgoliaList", {
                ref: "fuelCards",
                attrs: {
                  facets: _vm.facets,
                  filters: _vm.filters,
                  "item-actions": _vm.itemsActions,
                  index: _vm.ALGOLIA_INDEXES.fuelcards,
                  "table-config": _vm.mainConfig,
                  "data-test-id": "list",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.isModalVisible
        ? _c("FuelCardForm", {
            attrs: {
              "fuel-card-uuid": _vm.fuelCardUuid,
              callback: _vm.onFormSuccess,
              "data-test-id": "form",
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }