<script>
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapActions, mapState } from 'vuex';
import { DeleteModalBodyComponent, GenericModalComponent } from '@/components';
import { Cancel } from '@/constants/modalButtons.const';
import { scopes as DEVICE_SCOPES } from '../store/DevicesModule';

export default {
  name: 'DeleteDeviceComponent',
  components: {
    DeleteModalBodyComponent,
    GenericModalComponent,
  },
  props: {
    device: {
      type: Object,
      required: true,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.devices, {
      deleteDeviceStatus: state => state[DEVICE_SCOPES.deleteForm].STATUS,
    }),
    modalButtons() {
      return [
        {
          ...Cancel,
          listeners: {
            click: () => this.$emit('closeModal'),
          },
        },
        {
          attributes: {
            color: this.COLORS.danger,
            loading: this.deleteDeviceStatus.LOADING,
            disabled: this.deleteDeviceStatus.LOADING,
          },
          text: 'Delete',
          listeners: {
            click: this.removeDevice,
          },
        },
      ];
    },
  },
  created() {
    this.DOMAINS_MODEL = DOMAINS_MODEL;
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleet.devices, [
      'deleteDevice',
    ]),
    async removeDevice() {
      await this.deleteDevice(this.device.uuid);

      if (!this.deleteDeviceStatus.ERROR) {
        this.$notify({
          message: 'Delete deleted successfully!',
          textAction: '',
        });
        this.onSuccess();
        this.$emit('closeModal');
      }
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="null"
    :buttons="modalButtons"
    :size="SIZES.small"
    class="DeviceFormComponent"
    data-test-id="device-form"
    v-on="$listeners"
  >
    <template slot="body">
      <DeleteModalBodyComponent
        :text="`${device.device} - ${device.device_number} - ${device.label_number}`"
        title="Are you sure you want to remove this device?"
        data-test-id="delete_modal_body"
      />
    </template>
  </GenericModalComponent>
</template>
