var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "FuelCardForm",
        attrs: {
          title: _vm.modalTitle,
          header: { isClosable: true },
          "data-test-id": "fuel_card-form",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "alerts" },
        [
          _c("StoreNotificationComponent", {
            attrs: {
              "store-domain": _vm.DOMAINS_MODEL.fleet.fuelCards,
              scope: _vm.FUEL_CARD_SCOPES.newFuelCard,
              "is-editing": !!_vm.fuelCardUuid,
              element: "fuel card",
              "data-test-id": "notification",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          !_vm.isInitialized
            ? _c("ui-loader", {
                attrs: {
                  label: "Loading fuelcard...",
                  "data-test-id": "loader",
                },
              })
            : _c(
                "MuiValidationWrapper",
                {
                  attrs: { "data-test-id": "validation" },
                  on: { areAllValid: (valid) => (_vm.isFormValid = valid) },
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-6 emobg-border-right-1 emobg-border-color-ink-lighter emobg-border-radius-none",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "pr-2" },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "d-block emobg-font-weight-semibold mb-1",
                              },
                              [_vm._v(" Number* ")]
                            ),
                            _c("MuiInputText", {
                              staticClass: "w-100 mb-4",
                              attrs: {
                                placeholder: "Enter the fuel card number",
                                name: "number",
                                "data-test-id": "number-input",
                              },
                              model: {
                                value: _vm.inputs.number,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, "number", $$v)
                                },
                                expression: "inputs.number",
                              },
                            }),
                            _c("MuiSelect", {
                              staticClass: "w-100 mb-4",
                              attrs: {
                                options: _vm.map(
                                  _vm.CODE_ASSOCIATION,
                                  (code) => ({
                                    label: _vm.sentenceCase(code),
                                    value: code,
                                  })
                                ),
                                name: "codeAssociation",
                                placeholder: "Select the association code",
                                label: "Association code*",
                                "data-test-id": "association_code-select",
                              },
                              model: {
                                value: _vm.inputs.codeAssociation,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, "codeAssociation", $$v)
                                },
                                expression: "inputs.codeAssociation",
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticClass:
                                  "d-block emobg-font-weight-semibold mb-1",
                              },
                              [_vm._v(" PIN* ")]
                            ),
                            _c("MuiInputText", {
                              staticClass: "w-100 mb-4",
                              attrs: {
                                placeholder: "Enter the fuel PIN",
                                name: "fuelPin",
                                "data-test-id": "fuel_pin-input",
                              },
                              model: {
                                value: _vm.inputs.fuelPin,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, "fuelPin", $$v)
                                },
                                expression: "inputs.fuelPin",
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticClass:
                                  "d-block emobg-font-weight-semibold mb-1",
                              },
                              [_vm._v(" Expiry date ")]
                            ),
                            _c("MonthSelectorComponent", {
                              staticClass: "mb-4",
                              attrs: {
                                format: _vm.MONTH_FORMAT,
                                "hide-icon": "",
                                "disable-typing": "",
                                "data-test-id": "expiry_date-select",
                              },
                              on: {
                                change: (momentObject) =>
                                  _vm.validateDate(momentObject),
                              },
                              model: {
                                value: _vm.inputs.expiryDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, "expiryDate", $$v)
                                },
                                expression: "inputs.expiryDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "col-6" }, [
                      _c(
                        "div",
                        { staticClass: "pl-2" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "d-block emobg-font-weight-semibold mb-1",
                            },
                            [_vm._v(" Active ")]
                          ),
                          _c("ui-toggle", {
                            staticClass: "mb-4 py-1 d-block",
                            attrs: {
                              value: _vm.inputs.active,
                              text: _vm.inputs.active ? "Active" : "Inactive",
                              label: "Status",
                              name: "status",
                              "data-test-id": "active-toggle",
                            },
                            on: {
                              changevalue: ({ detail }) =>
                                (_vm.inputs.active = detail),
                            },
                          }),
                          _c(
                            "label",
                            {
                              staticClass:
                                "d-block emobg-font-weight-semibold mb-1",
                            },
                            [_vm._v(" Company* ")]
                          ),
                          _c("MuiInputText", {
                            staticClass: "w-100 mb-4",
                            attrs: {
                              placeholder: "Enter the fuel card number",
                              name: "company",
                              "data-test-id": "company-input",
                            },
                            model: {
                              value: _vm.inputs.company,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "company", $$v)
                              },
                              expression: "inputs.company",
                            },
                          }),
                          _c("MuiSelect", {
                            staticClass: "w-100 mb-4",
                            attrs: {
                              options: _vm.map(_vm.CONTRACT_TYPES, (type) => ({
                                label: _vm.sentenceCase(type),
                                value: type,
                              })),
                              name: "contractType",
                              placeholder: "Select the contract type",
                              label: "Contract type*",
                              "data-test-id": "contract_type-select",
                            },
                            model: {
                              value: _vm.inputs.contractType,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "contractType", $$v)
                              },
                              expression: "inputs.contractType",
                            },
                          }),
                          _vm.allowSecondaryFuelCard
                            ? _c(
                                "div",
                                { staticClass: "mb-4" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "d-block emobg-font-weight-semibold mb-1",
                                    },
                                    [_vm._v(" Fuel card type* ")]
                                  ),
                                  _vm._l(
                                    _vm.values(_vm.FUEL_CARD_TYPES),
                                    function (type) {
                                      return _c("ui-radio", {
                                        key: type,
                                        staticClass: "d-block mb-1",
                                        attrs: {
                                          value: _vm.inputs.type,
                                          option: type,
                                          caption: _vm.sentenceCase(type),
                                          name: "type",
                                          "data-test-id": `type_${type}-button`,
                                        },
                                        on: {
                                          changevalue: () =>
                                            (_vm.inputs.type = type),
                                        },
                                      })
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-12 mb-4" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "d-block emobg-font-weight-bold mb-1",
                          },
                          [_vm._v(" Description ")]
                        ),
                        _c("MuiTextarea", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                isRequired: true,
                                isMinLength: {
                                  message: "We need at least 6 characters",
                                  length: 6,
                                },
                              },
                              expression:
                                "{\n              isRequired: true,\n              isMinLength: {\n                message: 'We need at least 6 characters',\n                length: 6,\n              }\n            }",
                            },
                          ],
                          attrs: {
                            name: "description",
                            rows: "4",
                            "data-test-id": "description-textarea",
                          },
                          model: {
                            value: _vm.inputs.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "description", $$v)
                            },
                            expression: "inputs.description",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center justify-content-sm-end align-items-center",
          },
          [
            _c(
              "div",
              { staticClass: "d-flex justify-content-center" },
              [
                _c("CancelButton", {
                  attrs: { "data-test-id": "close_modal-button" },
                  on: { click: () => _vm.$emit("closeModal") },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex justify-content-center" },
              [
                _c(
                  "ui-button",
                  {
                    staticClass: "wmin-initial",
                    attrs: {
                      disabled:
                        !_vm.areRequiredFieldsFilled || _vm.hasSameValues,
                      loading: _vm.newFuelCardStatus.LOADING,
                      "data-test-id": "request-button",
                    },
                    on: { clickbutton: _vm.request },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.fuelCardUuid ? "Save" : "Create") + " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }