var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "DeleteSim",
        attrs: { header: null, size: _vm.SIZES.small },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c("DeleteModalBodyComponent", {
            attrs: {
              text: "This operation can not be undone",
              title: "Are you sure you want to delete this sim?",
              "data-test-id": "delete-sim-body",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("CancelButton", {
            on: {
              click: function ($event) {
                return _vm.$emit("closeModal")
              },
            },
          }),
          _c("DeleteButton", {
            attrs: {
              loading: _vm.deleteSimStatus.LOADING,
              "data-test-id": "delete-sim-button",
            },
            on: { click: _vm.removeSim },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }