<script>
import { MuiAlgoliaList } from '@emobg/motion-ui';

import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import {
  attributesToRetrieve,
  contentCells,
} from './config';

export default {
  name: 'DeviceProvidersListView',
  components: {
    MuiAlgoliaList,
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.attributesToRetrieve = attributesToRetrieve;
    this.contentCells = contentCells;
  },
};
</script>

<template>
  <div
    class="DeviceProvidersListView"
    data-test-id="device_providers_list-view"
  >
    <h1 class="mb-2">
      Device providers
    </h1>
    <MuiAlgoliaList
      :export-columns="contentCells"
      :table-config="contentCells"
      :query-parameters="{ attributesToRetrieve }"
      :index="ALGOLIA_INDEXES.deviceProviders"
      data-test-id="list"
      is-export-enabled
    />
  </div>
</template>
