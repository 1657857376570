<script>
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import toLower from 'lodash/toLower';
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { sentenceCase } from '@emobg/web-utils';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { CancelButton, GenericModalComponent, StoreNotificationComponent } from '@/components';
import fleet from '../../../../store/FleetModuleMap';
import { SIM_SCOPES } from '../../store/SimsModule';

export default {
  name: 'SimForm',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    GenericModalComponent,
    MuiInputText,
    MuiAlgoliaSelect,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },
  props: {
    simUuid: {
      type: String,
      default: null,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      isInitialized: false,
      originalInputs: {},
      inputs: {
        active: false,
        dataNumber: '',
        gprsIp: '',
        pin: '',
        provider: '',
        puk: '',
        simId: '',
        voiceNumber: '',
      },
    };
  },
  computed: {
    ...mapState(fleet.sims, {
      sim: state => state.sim.data,
      newSimStatus: state => state.newSim.STATUS,
      newSim: state => state.newSim.data,
    }),
    modalTitle() {
      return this.simUuid ? 'Edit sim' : 'Add sim';
    },
    areRequiredFieldsFilled() {
      return this.inputs.dataNumber
        && this.inputs.gprsIp
        && this.inputs.pin
        && this.inputs.simId
        && this.inputs.voiceNumber;
    },
    hasSameValues() {
      return isEqual(this.inputs, this.originalInputs);
    },
  },
  async created() {
    this.isInitialized = false;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.SIM_SCOPES = SIM_SCOPES;
    this.fleet = fleet;

    this.resetState([SIM_SCOPES.sim, SIM_SCOPES.newSim]);
    if (this.simUuid) {
      await this.getSim(this.simUuid);
      this.inputs.dataNumber = get(this, 'sim.dataNumber');
      this.inputs.gprsIp = get(this, 'sim.gprsIp');
      this.inputs.pin = get(this, 'sim.pin');
      this.inputs.provider = toLower(get(this, 'sim.provider', ''));
      this.inputs.puk = get(this, 'sim.puk');
      this.inputs.simId = get(this, 'sim.simId');
      this.inputs.voiceNumber = get(this, 'sim.voiceNumber');
      this.inputs.active = !!get(this, 'sim.active');
      this.originalInputs = cloneDeep(this.inputs);
    }

    this.isInitialized = true;
  },
  methods: {
    sentenceCase,
    async request() {
      this.clearErrors();
      const request = this.simUuid ? this.putSim : this.postSim;
      const params = this.simUuid ? { simUuid: this.simUuid, data: this.inputs } : this.inputs;
      await request(params);

      if (!this.newSimStatus.ERROR) {
        const { id } = this.newSim;
        const message = this.simUuid
          ? 'Sim edited successfully!'
          : `Sim added successfully with <span class="emobg-font-weight-bold">Id ${id}.</span>`;
        this.$emit('closeModal');
        this.$notify({
          message,
          textAction: '',
        });
        this.callback(id);
        this.resetState([SIM_SCOPES.sim, SIM_SCOPES.newSim]);
      }
    },
    ...mapActions(fleet.sims, [
      'getSim',
      'postSim',
      'putSim',
    ]),
    ...mapMutations(fleet.sims, [
      'resetState',
      'clearErrors',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :title="modalTitle"
    :header="{ isClosable: true }"
    class="SimForm"
    v-on="$listeners"
  >
    <template slot="alerts">
      <StoreNotificationComponent
        :store-domain="fleet.sims"
        :scope="SIM_SCOPES.newSim"
        :is-editing="!!simUuid"
        element="sim"
      />
    </template>
    <template slot="body">
      <ui-loader
        v-if="!isInitialized"
        label="Loading sim..."
      />
      <MuiValidationWrapper
        v-else
        @areAllValid="valid => isFormValid = valid"
      >
        <div class="row">
          <div class="col-6 emobg-border-right-1 emobg-border-color-ink-lighter emobg-border-radius-none">
            <div class="pr-2">
              <div class="mb-4">
                <MuiInputText
                  v-model="inputs.dataNumber"
                  v-validate="{ isRequired: true }"
                  label="Data number*"
                  placeholder="Enter the sim data number"
                  name="dataNumber"
                  class="w-100"
                />
              </div>

              <div class="mb-4">
                <MuiInputText
                  v-model="inputs.pin"
                  v-validate="{ isRequired: true }"
                  label="PIN*"
                  placeholder="Enter the PIN"
                  name="pin"
                  class="w-100"
                />
              </div>

              <div class="mb-4">
                <MuiInputText
                  v-model="inputs.gprsIp"
                  v-validate="{ isRequired: true }"
                  label="GPRS IP*"
                  placeholder="Enter the GPRS IP"
                  name="gprsIp"
                  class="w-100"
                />
              </div>

              <div class="mb-4">
                <MuiInputText
                  v-model="inputs.simId"
                  v-validate="{ isRequired: true }"
                  label="Sim number*"
                  placeholder="Enter the Sim number"
                  name="simId"
                  class="w-100"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="pl-2">
              <div class="mb-4">
                <label class="d-block emobg-font-weight-semibold mb-1">
                  Active
                </label>
                <ui-toggle
                  :value="inputs.active"
                  :text="inputs.active ? 'Active' : 'Inactive'"
                  class="py-1"
                  name="sim"
                  data-test-id="active-toggle"
                  @changevalue="({ detail }) => inputs.active = detail"
                />
              </div>

              <div class="mb-4">
                <MuiInputText
                  v-model="inputs.voiceNumber"
                  v-validate="{ isRequired: true }"
                  label="Voice number*"
                  placeholder="Enter the sim voice number"
                  name="voiceNumber"
                  class="w-100"
                />
              </div>

              <div class="mb-4">
                <MuiAlgoliaSelect
                  v-model="inputs.provider"
                  :index="ALGOLIA_INDEXES.deviceProviders"
                  :title="provider => provider.name"
                  label="Device provider"
                  name="provider"
                  no-cache
                  show-clear
                  path-value="name"
                  placeholder="Select a provider"
                  class="w-100"
                />
              </div>

              <div class="mb-4">
                <MuiInputText
                  v-model="inputs.puk"
                  label="Puk"
                  placeholder="Enter the sim puk"
                  name="puk"
                  class="w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </MuiValidationWrapper>
    </template>
    <template slot="footer">
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton @click="() => $emit('closeModal')" />

        <ui-button
          :disabled="!areRequiredFieldsFilled || hasSameValues"
          :loading="newSimStatus.LOADING"
          class="wmin-initial"
          data-test-id="sim-form-action-button"
          @clickbutton="request"
        >
          {{ simUuid ? 'Save' : 'Create' }}
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
