export { contentCells } from './contentCells';

export const facets = [
  {
    type: 'refinementList',
    props: {
      title: 'Communication system',
      attributeName: 'communication_system',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Type',
      attributeName: 'type',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Vehicle model',
      attributeName: 'vehicle_model',
    },
  },
];
