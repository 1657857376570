<script>
import { mapActions, mapState } from 'vuex';
import {
  CancelButton,
  DeleteButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';
import fleet from '../../../../store/FleetModuleMap';

export default {
  components: {
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
    DeleteButton,
  },
  props: {
    simUuid: {
      type: String,
      required: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(fleet.sims, {
      deleteSimStatus: state => state.deleteSim.STATUS,
    }),
  },
  methods: {
    async removeSim() {
      await this.deleteSim(this.simUuid);
      this.$emit('closeModal');
      if (!this.deleteSimStatus.ERROR) {
        this.$notify({
          message: 'Sim deleted successfully!',
          textAction: '',
        });
        this.callback();
      }
    },
    ...mapActions(fleet.sims, [
      'deleteSim',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="DeleteSim"
    v-on="$listeners"
  >
    <template slot="body">
      <DeleteModalBodyComponent
        text="This operation can not be undone"
        title="Are you sure you want to delete this sim?"
        data-test-id="delete-sim-body"
      />
    </template>
    <template slot="footer">
      <CancelButton @click="$emit('closeModal')" />
      <DeleteButton
        :loading="deleteSimStatus.LOADING"
        data-test-id="delete-sim-button"
        @click="removeSim"
      />
    </template>
  </GenericModalComponent>
</template>
