import get from 'lodash/get';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import { FALLBACK_MESSAGE, isFalsyString } from '@emobg/web-utils';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

export function contentCells() {
  return [
    {
      title: 'ID',
      attributeName: 'id',
      displayPriority: 1,
    },
    {
      title: 'Data number',
      attributeName: 'data_number',
      displayPriority: 1,
    },
    {
      title: 'Voice number',
      attributeName: 'voice_number',
      displayPriority: 1,
    },
    {
      attributeName: 'active',
      title: 'Status',
      displayPriority: 1,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: ({ active }) => ({
        text: active ? 'Active' : 'Inactive',
        color: active ? COLORS.success : GRAYSCALE.ground,
      }),
    },
    {
      title: 'GPRS IP',
      attributeName: 'gprs_ip',
      displayPriority: 0,
    },
    {
      title: 'Sim number',
      attributeName: 'sim_id',
      displayPriority: 0,
    },
    {
      title: 'Device',
      transformResult: result => (!isFalsyString(result.device) ? `${get(result, 'device.id')} - ${get(result, 'device.device')}` : FALLBACK_MESSAGE.dash),
      displayPriority: 0,
    },
  ];
}

export const facets = [
  {
    type: 'refinementList',
    props: {
      title: 'Status',
      attributeName: 'active',
      transformValue: active => (active === '1' ? 'Active' : 'Inactive'),
    },
  },
];
