import moment from 'moment-timezone';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import { FALLBACK_MESSAGE, sentenceCase } from '@emobg/web-utils';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

const EXPIRATION_DATE_FORMAT = 'MM/YYYY';

export function contentCells({ allowSecondaryFuelCard }) {
  const cells = [
    {
      title: 'ID',
      attributeName: 'id',
      displayPriority: 1,
    },
    {
      title: 'Number',
      attributeName: 'number',
      displayPriority: 1,
    },
    {
      title: 'Company',
      attributeName: 'company',
      displayPriority: 1,
    },
    {
      title: 'Description',
      attributeName: 'description',
      displayPriority: 1,
      minWidth: 250,
    },
    {
      title: 'Fuel pin',
      attributeName: 'fuel_pin',
      displayPriority: 1,
    },
    {
      attributeName: 'active',
      title: 'Status',
      displayPriority: 1,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: ({ active }) => ({
        text: active ? 'Active' : 'Inactive',
        color: active ? COLORS.success : GRAYSCALE.ground,
      }),
    },
    {
      title: 'Expiry date',
      attributeName: 'expiry_date',
      transformValue: value => (value ? moment(value).format(EXPIRATION_DATE_FORMAT) : FALLBACK_MESSAGE.dash),
      displayPriority: 1,
    },
  ];

  if (allowSecondaryFuelCard) {
    cells.splice(6, 0, {
      title: 'Type',
      attributeName: 'type',
      transformValue: sentenceCase,
      displayPriority: 1,
    });
  }

  return cells;
}

export const facets = [
  {
    type: 'refinementList',
    props: {
      title: 'Fuel card type',
      attributeName: 'type',
      transformValue: sentenceCase,
    },
  },
];
