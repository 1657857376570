import { sentenceCase } from '@emobg/web-utils';

export const contentCells = [
  {
    attributeName: 'id',
    title: 'ID',
    displayPriority: 1,
    minWidth: 100,
  },
  {
    attributeName: 'name',
    title: 'Name',
    displayPriority: 1,
    minWidth: 150,
    transformValue: sentenceCase,
  },
];
