var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "DeviceFormComponent",
        attrs: {
          title: _vm.modalTitle,
          header: { isClosable: true },
          buttons: _vm.modalButtons,
          "data-test-id": "device-form",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "alerts" },
        [
          _c("StoreNotificationComponent", {
            attrs: {
              "store-domain": _vm.DOMAINS_MODEL.fleet.devices,
              scope: _vm.DEVICE_SCOPES.form,
              "is-editing": !!_vm.deviceUuid,
              element: "device",
              "data-test-id": "store-notification",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: { label: "Loading device...", "data-test-id": "loader" },
              })
            : _c(
                "MuiValidationWrapper",
                {
                  attrs: { id: "deviceForm", "data-test-id": "submit-form" },
                  on: {
                    areAllValid: (isValid) => (_vm.isFormValid = isValid),
                    submit: _vm.request,
                  },
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-sm-6 emobg-border-right-1 emobg-border-color-ink-lighter",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _c("MuiInputText", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    isRequired: true,
                                  },
                                  expression:
                                    "{\n                isRequired: true,\n              }",
                                },
                              ],
                              staticClass: "w-100",
                              attrs: {
                                label: "Device*",
                                name: "device",
                                placeholder: "Enter the device here",
                                "data-test-id": "device-input",
                              },
                              model: {
                                value: _vm.inputs.device,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, "device", $$v)
                                },
                                expression: "inputs.device",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _c("MuiInputText", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    isRequired: true,
                                  },
                                  expression:
                                    "{\n                isRequired: true,\n              }",
                                },
                              ],
                              staticClass: "w-100",
                              attrs: {
                                label: "Device number*",
                                name: "device_number",
                                placeholder: "Enter the device number here",
                                "data-test-id": "device_number-input",
                              },
                              model: {
                                value: _vm.inputs.deviceNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, "deviceNumber", $$v)
                                },
                                expression: "inputs.deviceNumber",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _c("MuiInputText", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    isRequired: true,
                                  },
                                  expression:
                                    "{\n                isRequired: true,\n              }",
                                },
                              ],
                              staticClass: "w-100",
                              attrs: {
                                label: "Label number*",
                                name: "label_number*",
                                placeholder: "Enter the label number here",
                                "data-test-id": "label_number-input",
                              },
                              model: {
                                value: _vm.inputs.labelNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, "labelNumber", $$v)
                                },
                                expression: "inputs.labelNumber",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _c("MuiInputText", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    isRequired: true,
                                  },
                                  expression:
                                    "{\n                isRequired: true,\n              }",
                                },
                              ],
                              staticClass: "w-100",
                              attrs: {
                                label: "Firmware version*",
                                name: "firmware_version*",
                                placeholder: "Enter the firmware version here",
                                "data-test-id": "firmware_version-input",
                              },
                              model: {
                                value: _vm.inputs.firmwareVersion,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, "firmwareVersion", $$v)
                                },
                                expression: "inputs.firmwareVersion",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _c("MuiInputText", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    isRequired: true,
                                  },
                                  expression:
                                    "{\n                isRequired: true,\n              }",
                                },
                              ],
                              staticClass: "w-100",
                              attrs: {
                                label: "Orga ref*",
                                name: "orga_ref",
                                placeholder: "Enter the orga ref here",
                                "data-test-id": "orga_ref-input",
                              },
                              model: {
                                value: _vm.inputs.orgaRef,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, "orgaRef", $$v)
                                },
                                expression: "inputs.orgaRef",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _c("MuiInputText", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    isRequired: true,
                                  },
                                  expression:
                                    "{\n                isRequired: true,\n              }",
                                },
                              ],
                              staticClass: "w-100",
                              attrs: {
                                label: "Qnr*",
                                name: "qnr",
                                placeholder: "Enter the qnr here",
                                "data-test-id": "qnr-input",
                              },
                              model: {
                                value: _vm.inputs.qnr,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, "qnr", $$v)
                                },
                                expression: "inputs.qnr",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "col-12 col-sm-6" }, [
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _c("ui-toggle", {
                            attrs: {
                              value: _vm.inputs.active,
                              text: _vm.inputs.active
                                ? "Device is active"
                                : "Device is inactive",
                              label: "Active",
                              "data-test-id": "active-toggle",
                              name: "active",
                            },
                            on: {
                              changevalue: ({ detail }) =>
                                (_vm.inputs.active = detail),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _c("MuiAlgoliaSelect", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                },
                                expression:
                                  "{\n                isRequired: true,\n              }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              index: _vm.ALGOLIA_INDEXES.deviceProviders,
                              title: (deviceProvider) => deviceProvider.name,
                              "path-value": "uuid",
                              label: "Device provider*",
                              name: "device_provider_uuid",
                              placeholder: "Select a device provider",
                              "data-test-id": "provider-select",
                            },
                            model: {
                              value: _vm.inputs.deviceProviderUuid,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "deviceProviderUuid", $$v)
                              },
                              expression: "inputs.deviceProviderUuid",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _c("MuiAlgoliaSelect", {
                            staticClass: "w-100",
                            attrs: {
                              index: _vm.ALGOLIA_INDEXES.sims,
                              title: (sim) => sim.data_number,
                              "on-clear": () => null,
                              "use-filters-model": false,
                              filters: "device:'null' AND active:1",
                              "show-clear": "",
                              "path-value": "uuid",
                              label: "Sim",
                              name: "sim_uuid",
                              placeholder: "Select a sim",
                              "data-test-id": "sim_uuid-select",
                            },
                            model: {
                              value: _vm.inputs.simUuid,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "simUuid", $$v)
                              },
                              expression: "inputs.simUuid",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _c("MuiSelect", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                },
                                expression:
                                  "{\n                isRequired: true,\n              }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              options: _vm.DEVICE_TYPE_VALUES,
                              label: "Type*",
                              name: "type",
                              placeholder: "Select the type of the device",
                              "data-test-id": "type-select",
                            },
                            model: {
                              value: _vm.inputs.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "type", $$v)
                              },
                              expression: "inputs.type",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _c("MuiSelect", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                },
                                expression:
                                  "{\n                isRequired: true,\n              }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              options: _vm.DEVICE_COMMUNICATION_SYSTEM_OPTIONS,
                              label: "Communication system*",
                              name: "type",
                              placeholder: "Select a communication system",
                              "data-test-id": "communication_system-select",
                            },
                            model: {
                              value: _vm.inputs.communicationSystem,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "communicationSystem", $$v)
                              },
                              expression: "inputs.communicationSystem",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }