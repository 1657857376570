export const FUEL_CARD_TYPES = {
  primary: 'primary',
  secondary: 'secondary',
};

export const CONTRACT_TYPES = {
  internal: 'internal',
  client: 'client',
};

export const CODE_ASSOCIATION = {
  list: 'list',
  unique: 'unique',
};
