<script>
import { DELAY } from '@emobg/web-utils';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { refreshAlgoliaStore } from '@/utils/algolia';
import { contentCells, facets } from './config';
import SimForm from './components/SimForm';
import DeleteSimComponent from './components/DeleteSimComponent';

export default {
  name: 'SimsListView',
  components: {
    MuiAlgoliaList,
    SimForm,
    DeleteSimComponent,
  },
  data() {
    return {
      isLoading: false,
      isModalVisible: false,
      isDeleteModalVisible: false,
      simUuid: null,
    };
  },
  created() {
    const element = 'sim';
    this.mainConfig = contentCells();
    this.facets = facets;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.itemsActions = [
      {
        label: this.$t('Common.Actions.edit_element', { element }),
        method: ({ uuid }) => {
          this.simUuid = uuid;
          this.isModalVisible = true;
        },
      },
      {
        label: `Delete ${element}`,
        type: 'danger',
        class: 'emobg-color-danger',
        method: ({ uuid }) => {
          this.simUuid = uuid;
          this.isDeleteModalVisible = true;
        },
      },
    ];
  },
  methods: {
    closeModal() {
      this.isModalVisible = false;
      this.isDeleteModalVisible = false;
      this.simUuid = null;
    },
    onFormSuccess() {
      this.isLoading = true;
      refreshAlgoliaStore(this.$refs.sims, DELAY.extraLong, () => { this.isLoading = false; });
    },
  },
};
</script>

<template>
  <div
    class="SimsListView"
    data-test-id="sims-list"
  >
    <div class="d-flex align-items-center mb-2">
      <h1 class="flex-grow-1">
        Sims
      </h1>
      <ui-button
        data-test-id="create-button"
        @clickbutton="isModalVisible = true"
      >
        Add sim
      </ui-button>
    </div>

    <div class="position-relative">
      <ui-loader
        v-if="isLoading"
        absolute
      />
      <MuiAlgoliaList
        ref="sims"
        :facets="facets"
        :item-actions="itemsActions"
        :index="ALGOLIA_INDEXES.sims"
        :table-config="mainConfig"
        data-test-id="list"
      />
      <SimForm
        v-if="isModalVisible"
        :sim-uuid="simUuid"
        :callback="onFormSuccess"
        @closeModal="closeModal"
      />
      <DeleteSimComponent
        v-if="isDeleteModalVisible"
        :sim-uuid="simUuid"
        :callback="onFormSuccess"
        @closeModal="closeModal"
      />
    </div>
  </div>
</template>
