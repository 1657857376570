<script>
import assign from 'lodash/assign';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiSelect,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { GenericModalComponent, StoreNotificationComponent } from '@/components';
import { Cancel } from '@/constants/modalButtons.const';
import { DEVICE_COMMUNICATION_SYSTEM_OPTIONS, DEVICE_TYPE_VALUES } from '../const';
import { scopes as DEVICE_SCOPES } from '../store/DevicesModule';

export default {
  name: 'DeviceFormComponent',
  components: {
    GenericModalComponent,
    MuiAlgoliaSelect,
    MuiInputText,
    MuiSelect,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },
  directives: { Validate },
  props: {
    deviceUuid: {
      type: String,
      default: '',
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      isFormValid: false,
      inputs: {
        active: false,
        deviceProviderUuid: null,
        simUuid: null,
        type: null,
        communicationSystem: null,
        device: '',
        deviceNumber: '',
        labelNumber: '',
        firmwareVersion: '',
        orgaRef: '',
        qnr: '',
      },
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, ['activeOperator']),
    ...mapState(DOMAINS_MODEL.fleet.devices, {
      device: state => state.data,
      deviceStatus: state => state.STATUS,
      deviceForm: state => state[DEVICE_SCOPES.form].data,
      deviceFormStatus: state => state[DEVICE_SCOPES.form].STATUS,
    }),
    modalTitle() {
      return this.deviceUuid
        ? `Edit ${get(this.device, 'device', this.FALLBACK_MESSAGE.dash)}`
        : 'Add device';
    },
    modalButtons() {
      return [
        {
          ...Cancel,
          listeners: {
            click: () => this.$emit('closeModal'),
          },
        },
        {
          attributes: {
            form: 'deviceForm',
            type: 'submit',
            loading: this.deviceFormStatus.LOADING,
            disabled: !this.isFormValid || this.isLoading,
          },
          text: this.deviceUuid ? 'Save' : 'Create',
        },
      ];
    },
  },
  async created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.DEVICE_TYPE_VALUES = DEVICE_TYPE_VALUES;
    this.DEVICE_COMMUNICATION_SYSTEM_OPTIONS = DEVICE_COMMUNICATION_SYSTEM_OPTIONS;
    this.DEVICE_SCOPES = DEVICE_SCOPES;
    this.DOMAINS_MODEL = DOMAINS_MODEL;

    if (this.deviceUuid) {
      this.isLoading = true;
      await this.getDevice(this.deviceUuid);

      if (!this.deviceStatus.ERROR) {
        assign(this.inputs, omit(this.device, ['uuid']));
      }

      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleet.devices, [
      'getDevice',
      'postDevice',
      'putDevice',
    ]),
    async request() {
      const request = this.deviceUuid ? this.putDevice : this.postDevice;
      const params = this.deviceUuid
        ? { deviceUuid: this.deviceUuid, data: this.inputs }
        : { ...this.inputs, cs_operator_uuid: get(this.activeOperator, 'uuid') };
      await request(params);

      if (!this.deviceFormStatus.ERROR) {
        const { id } = this.deviceForm;
        const message = this.deviceUuid
          ? 'Device edited successfully!'
          : `Device added successfully with <span class="emobg-font-weight-bold">Id ${id}.</span>`;
        this.$notify({
          message,
          textAction: '',
        });
        this.onSuccess();
        this.$emit('closeModal');
      }
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :title="modalTitle"
    :header="{ isClosable: true }"
    :buttons="modalButtons"
    class="DeviceFormComponent"
    data-test-id="device-form"
    v-on="$listeners"
  >
    <template slot="alerts">
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.fleet.devices"
        :scope="DEVICE_SCOPES.form"
        :is-editing="!!deviceUuid"
        element="device"
        data-test-id="store-notification"
      />
    </template>
    <template slot="body">
      <ui-loader
        v-if="isLoading"
        label="Loading device..."
        data-test-id="loader"
      />
      <MuiValidationWrapper
        v-else
        id="deviceForm"
        data-test-id="submit-form"
        @areAllValid="isValid => isFormValid = isValid"
        @submit="request"
      >
        <div class="row">
          <div class="col-12 col-sm-6 emobg-border-right-1 emobg-border-color-ink-lighter">
            <div class="mb-4">
              <MuiInputText
                v-model="inputs.device"
                v-validate="{
                  isRequired: true,
                }"
                label="Device*"
                class="w-100"
                name="device"
                placeholder="Enter the device here"
                data-test-id="device-input"
              />
            </div>
            <div class="mb-4">
              <MuiInputText
                v-model="inputs.deviceNumber"
                v-validate="{
                  isRequired: true,
                }"
                label="Device number*"
                class="w-100"
                name="device_number"
                placeholder="Enter the device number here"
                data-test-id="device_number-input"
              />
            </div>
            <div class="mb-4">
              <MuiInputText
                v-model="inputs.labelNumber"
                v-validate="{
                  isRequired: true,
                }"
                label="Label number*"
                class="w-100"
                name="label_number*"
                placeholder="Enter the label number here"
                data-test-id="label_number-input"
              />
            </div>
            <div class="mb-4">
              <MuiInputText
                v-model="inputs.firmwareVersion"
                v-validate="{
                  isRequired: true,
                }"
                label="Firmware version*"
                class="w-100"
                name="firmware_version*"
                placeholder="Enter the firmware version here"
                data-test-id="firmware_version-input"
              />
            </div>
            <div class="mb-4">
              <MuiInputText
                v-model="inputs.orgaRef"
                v-validate="{
                  isRequired: true,
                }"
                label="Orga ref*"
                class="w-100"
                name="orga_ref"
                placeholder="Enter the orga ref here"
                data-test-id="orga_ref-input"
              />
            </div>
            <div class="mb-4">
              <MuiInputText
                v-model="inputs.qnr"
                v-validate="{
                  isRequired: true,
                }"
                label="Qnr*"
                class="w-100"
                name="qnr"
                placeholder="Enter the qnr here"
                data-test-id="qnr-input"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="mb-4">
              <ui-toggle
                :value="inputs.active"
                :text="inputs.active ? 'Device is active' : 'Device is inactive'"
                label="Active"
                data-test-id="active-toggle"
                name="active"
                @changevalue="({ detail }) => inputs.active = detail"
              />
            </div>
            <div class="mb-4">
              <MuiAlgoliaSelect
                v-model="inputs.deviceProviderUuid"
                v-validate="{
                  isRequired: true,
                }"
                :index="ALGOLIA_INDEXES.deviceProviders"
                :title="deviceProvider => deviceProvider.name"
                path-value="uuid"
                label="Device provider*"
                class="w-100"
                name="device_provider_uuid"
                placeholder="Select a device provider"
                data-test-id="provider-select"
              />
            </div>
            <div class="mb-4">
              <MuiAlgoliaSelect
                v-model="inputs.simUuid"
                :index="ALGOLIA_INDEXES.sims"
                :title="sim => sim.data_number"
                :on-clear="() => null"
                :use-filters-model="false"
                filters="device:'null' AND active:1"
                show-clear
                path-value="uuid"
                label="Sim"
                class="w-100"
                name="sim_uuid"
                placeholder="Select a sim"
                data-test-id="sim_uuid-select"
              />
            </div>
            <div class="mb-4">
              <MuiSelect
                v-model="inputs.type"
                v-validate="{
                  isRequired: true,
                }"
                :options="DEVICE_TYPE_VALUES"
                label="Type*"
                class="w-100"
                name="type"
                placeholder="Select the type of the device"
                data-test-id="type-select"
              />
            </div>
            <div class="mb-4">
              <MuiSelect
                v-model="inputs.communicationSystem"
                v-validate="{
                  isRequired: true,
                }"
                :options="DEVICE_COMMUNICATION_SYSTEM_OPTIONS"
                label="Communication system*"
                class="w-100"
                name="type"
                placeholder="Select a communication system"
                data-test-id="communication_system-select"
              />
            </div>
          </div>
        </div>
      </MuiValidationWrapper>
    </template>
  </GenericModalComponent>
</template>
