<script>
import get from 'lodash/get';
import includes from 'lodash/includes';
import { DELAY } from '@emobg/web-utils';
import { mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { refreshAlgoliaStore } from '@/utils/algolia';
import { contentCells, facets } from './config';
import FuelCardForm from './components/FuelCardForm';

export default {
  name: 'FuelCardsListView',
  components: {
    FuelCardForm,
    MuiAlgoliaList,
  },
  data() {
    return {
      isLoading: false,
      isModalVisible: false,
      fuelCardUuid: null,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      isUserLoaded: state => get(state, 'user.STATUS.LOADED'),
      permissions: state => get(state, 'user.data.permissions'),
    }),
    mainConfig() {
      return contentCells({ allowSecondaryFuelCard: this.allowSecondaryFuelCard });
    },
    facets() {
      return this.allowSecondaryFuelCard ? facets : [];
    },
    allowSecondaryFuelCard() {
      return includes(this.permissions, 'allow_secondary_fuelcard');
    },
    filters() {
      return !this.allowSecondaryFuelCard ? 'type: primary' : '';
    },
  },
  created() {
    const element = 'fuel card';
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.itemsActions = [
      {
        label: this.$t('Common.Actions.edit_element', { element }),
        method: ({ uuid }) => {
          this.fuelCardUuid = uuid;
          this.isModalVisible = true;
        },
      },
    ];
  },
  methods: {
    closeModal() {
      this.isModalVisible = false;
      this.fuelCardUuid = null;
    },
    onFormSuccess() {
      this.isLoading = true;
      refreshAlgoliaStore(this.$refs.fuelCards, DELAY.extraLong, () => { this.isLoading = false; });
    },
  },
};
</script>

<template>
  <div
    class="FuelCardsListView"
    data-test-id="fuel_cards_list-view"
  >
    <div class="d-flex align-items-center mb-2">
      <h1 class="flex-grow-1">
        Fuel cards
      </h1>
      <ui-button
        data-test-id="create-button"
        @clickbutton="isModalVisible = true"
      >
        Add fuel card
      </ui-button>
    </div>

    <div class="position-relative">
      <ui-loader
        v-if="isLoading"
        absolute
        data-test-id="loader"
      />
      <MuiAlgoliaList
        v-if="isUserLoaded"
        ref="fuelCards"
        :facets="facets"
        :filters="filters"
        :item-actions="itemsActions"
        :index="ALGOLIA_INDEXES.fuelcards"
        :table-config="mainConfig"
        data-test-id="list"
      />
    </div>
    <FuelCardForm
      v-if="isModalVisible"
      :fuel-card-uuid="fuelCardUuid"
      :callback="onFormSuccess"
      data-test-id="form"
      @closeModal="closeModal"
    />
  </div>
</template>
