<script>
import get from 'lodash/get';
import { mapGetters } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { DELAY } from '@emobg/web-utils';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import DOMAINS_MODEL from '@/domains/DOMAINS_MODEL';
import { refreshAlgoliaStore } from '@/utils/algolia';
import {
  contentCells,
  facets,
} from './config';
import DeviceFormComponent from '../components/DeviceFormComponent';
import DeleteDeviceComponent from '../components/DeleteDeviceComponent';

export default {
  name: 'DevicesListView',
  components: {
    DeleteDeviceComponent,
    DeviceFormComponent,
    MuiAlgoliaList,
  },
  data() {
    return {
      isLoading: false,
      isFormOpened: false,
      isDeleteFormOpened: false,
      uuidToEdit: null,
      device: null,
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
  },
  created() {
    this.MAX_SEARCH_RETRIES = 5;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.contentCells = contentCells;
    this.facets = facets;
    this.itemsActions = [
      {
        label: this.$t('Common.Actions.edit_element', { element: 'device' }),
        method: ({ uuid }) => {
          this.uuidToEdit = uuid;
          this.isFormOpened = true;
        },
      },
      {
        label: 'Remove device',
        class: 'emobg-color-danger',
        method: device => {
          this.device = device;
          this.isDeleteFormOpened = true;
        },
      },
    ];
  },
  mounted() {
    const querySearch = get(this.$route.query, 'query');
    if (querySearch) {
      this.searchDeviceInList(querySearch);
    }
  },
  methods: {
    refreshList() {
      this.isLoading = true;
      refreshAlgoliaStore(this.$refs.devicesList, DELAY.extraLong, () => { this.isLoading = false; });
    },
    closeModal() {
      this.isFormOpened = false;
      this.isDeleteFormOpened = false;
      this.uuidToEdit = null;
      this.device = null;
    },
    searchDeviceInList(search) {
      let retryCount = 1;
      const initListInterval = setInterval(() => {
        if (retryCount >= this.MAX_SEARCH_RETRIES) {
          clearInterval(initListInterval);
          return;
        }
        if (this.$refs.devicesList.isStoreReady) {
          retryCount = this.MAX_SEARCH_RETRIES;
          const searchbox = this.$refs.devicesList.$el.querySelector('.MuiSearchBox input');
          searchbox.value = search;
          searchbox.dispatchEvent(new Event('input'));
        }
        retryCount += 1;
      }, DELAY.short);
    },
  },
};
</script>

<template>
  <div
    class="DeviceProvidersListView"
    data-test-id="devices_list-view"
  >
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h1>
        Devices
      </h1>
      <ui-button
        data-test-id="open_form-button"
        @clickbutton="isFormOpened = true"
      >
        Create device
      </ui-button>
    </div>
    <div class="position-relative">
      <ui-loader
        v-if="isLoading"
        label="Loading devices list..."
        absolute
        data-test-id="loader"
      />
      <MuiAlgoliaList
        ref="devicesList"
        :item-actions="itemsActions"
        :export-columns="contentCells"
        :facets="facets"
        :filters="getOperatorFilter()"
        :index="ALGOLIA_INDEXES.devices"
        :table-config="contentCells"
        data-test-id="list"
        is-export-enabled
      />
    </div>
    <DeviceFormComponent
      v-if="isFormOpened"
      :device-uuid="uuidToEdit"
      :on-success="refreshList"
      data-test-id="edit-form"
      @closeModal="closeModal"
    />
    <DeleteDeviceComponent
      v-if="isDeleteFormOpened"
      :device="device"
      :on-success="refreshList"
      data-test-id="delete_device"
      @closeModal="closeModal"
    />
  </div>
</template>
