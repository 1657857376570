import values from 'lodash/values';

export const DEVICE_TYPES = {
  embedded: 'embedded',
  standalone: 'standalone',
};

export const DEVICE_TYPE_VALUES = values(DEVICE_TYPES);

export const DEVICE_COMMUNICATION_SYSTEMS = {
  gprs: 'GPRS',
  smsWithModem: 'SMSWithModem',
};

export const DEVICE_COMMUNICATION_SYSTEM_OPTIONS = [
  { label: 'GPRS', value: DEVICE_COMMUNICATION_SYSTEMS.gprs },
  { label: 'SMS with modem', value: DEVICE_COMMUNICATION_SYSTEMS.smsWithModem },
];
